import EasyPaisa from "../assets/i/easypaisa.png";
import JazzCash from "../assets/i/jazz-cash.png";
import SimSim from "../assets/i/simsim.png";
import Visa from "../assets/i/visa.png";
import IBFT from "../assets/i/IBFT.png";
import hbl from "../assets/i/hbl.png";
import COD from "../assets/i/COD.png";
import axios from '../axios_purchase'

function styleDocument({key, style}) {
    document.documentElement.style.setProperty(
        key,
        style,
        "important"
    );
}

export const loadData = async (queryParams) => {
    let url = "//" + window.location.host;
    let url2 = "//" + window.location.host;
    if (
        localStorage.getItem("actualHost") === window.location.host &&
        localStorage.hasOwnProperty("branchHost") &&
        localStorage.getItem("branchHost") !== ""
    ) {
        url = "//" + localStorage.getItem("branchHost");
    } else {
        localStorage.setItem("actualHost", window.location.host);
        localStorage.setItem("branchHost", "");
    }
    if (window.location.href.indexOf("combined-view") > 0) {
        url = "testMarket.dukan.pk";
    }
    //url = "knowledgestreams.dukan.pk";
    const postData = {
        url,
        market_id: queryParams.market ?? "",
        previewmode: window.$isPreview,
    };

    const headers = {
        "Content-Type": "application/json",
    };

    try {
        let res1 = await axios.post(`${window.$storeUrl}${window.$apiRoot}/storefront/loadInitialData`, postData, {
            headers: {
                "Content-Type": "application/json",
                Accept: "*",
                // Add other necessary headers here
            },
            //  withCredentials: false, // You can try setting this to false if credentials are not needed
        });

        if (res1?.data?.code !== "11") {
            if (window.$retryIntialData === 1) {
                window.$retryIntialData = 0;
                localStorage.setItem("actualHost", window.location.host);
                localStorage.setItem("branchHost", "");
                localStorage.removeItem("branchData", "");
                postData.url = url2;
                res1 = await axios.post(`${window.$storeUrl}${window.$apiRoot}/storefront/loadInitialData`, postData, {
                    headers,
                });
                if (res1?.data?.code !== "11") return;
            }
        }

        const res = res1;

        if (res?.data?.redirect === "Y") {
            window.open(res?.data?.redirect_url, "_self");
            return false;
        }

        if (
            res.data?.data?.branches.data.count > 1 &&
            res.data?.data?.config[0].branch_selection_type.toString() === "3"
        ) {
            window.$showBranchModal = true;
        }

        let styleData = null;
        if (res?.data?.data?.themeData) styleData = res?.data?.data?.themeData;
        else styleData = res?.data?.data;

        if (styleData) {
            const {
                store_primary_color,
                store_secondary_color,
                store_bg_color,
                store_btn_color,
                store_icon_color,
                store_secondry_btn_color,
            } = styleData;
            styleData = {
                "--primary-color": store_primary_color,
                "--secondary-color": store_secondary_color,
                "--background": store_bg_color,
                "--button-primary": store_btn_color,
                "--icon": store_icon_color,
                "--button-secondary": store_secondry_btn_color,
            };
            for (const key in styleData) {
                const style = styleData[key];
                if (style && style !== "null") {
                    styleDocument({
                        key,
                        style,
                    });
                }
            }
        }

        if (res?.data?.data?.is_market === "N") {
            window.$storeId = res?.data?.data?.store?.data?.store_id.toString() ?? "";
            return await res?.data?.data;
        }

        window.$marketId = res?.data?.data?.market_id ?? "";
        window.$isMarket = true;
        return await res?.data?.data;
    } catch (error) {
        console.error("Error loading data:", error);
        return null;
    }
};

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};

export const ucFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const triggerApp = (ftn) => {
    document.getElementById("triggerApp").href = "inapp://" + ftn;

    document.getElementById("triggerApp").click();
};

export const getDynamicLogo = (payment_method_name) => {
    let logo = "";
    switch (payment_method_name) {
        case "Cash On Delivery":
            logo = COD;
            break;
        case "EasyPay":
            logo = EasyPaisa;

            break;
        case "Jazz Cash":
            logo = JazzCash;

            break;
        case "SIMSIM":
            logo = SimSim;

            break;
        case "Credit Card":
            logo = Visa;
            break;
        case "IBFT":
            logo = IBFT;

            break;
        case "HBL Payment":
            logo = hbl;

            break;

        default:
            break;
    }
    return logo;
};

export const calculateRating = (ratingArray) => {
    var ratings = [];
    if (ratingArray) {
        ratingArray.map((item) => {
            if (item.rating > 0 && item.rating <= 1) {
                ratings[1] = item.total_ratings;
            } else if (item.rating > 1 && item.rating <= 2) {
                ratings[2] = item.total_ratings;
            } else if (item.rating > 2 && item.rating <= 3) {
                ratings[3] = item.total_ratings;
            } else if (item.rating > 3 && item.rating <= 4) {
                ratings[4] = item.total_ratings;
            } else if (item.rating > 4 && item.rating <= 5) {
                ratings[5] = item.total_ratings;
            }
        });
    }
    return ratings;
};

export const percentage = (partialValue, totalValue) => {
    if (partialValue && totalValue) {
        return (100 * partialValue) / totalValue;
    }
    return 0;
};

export const dateFormat = (dt) => {
    var d = new Date(dt);
    return d.toDateString();
};

export const NumberFormat = (x) => {
    if (x) {
        x = x.toFixed(2);
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return 0;
    }
};

/**
 * add delay half second
 *
 * @param {Function} func
 * @param {Number} delay
 */
export function debounce(func, delay = 500) {
    let timer = null;
    return function (...args) {
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

/**
 *
 * @param {String} value
 * @returns {String}
 */
export function toBase64(value) {
    return Buffer.from(value).toString("base64");
}

/**
 *
 * @param {String} value
 * @returns {String}
 */
export function toStringAscii(value) {
    return Buffer.from(value, "base64").toString("ascii");
}

export function convert24to12(time, widthSeconds = false) {
    let [hours, minutes, seconds] = time.split(":");
    let date = new Date();
    date.setHours(+hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };
    if (widthSeconds) options.second = "numeric";
    return date.toLocaleTimeString("en-US", options);
}