import { useLocation, useHistory } from "react-router-dom";
import { useState, useEffect, lazy, Suspense, useRef } from "react";
import ScrollToTop from "../ScrollToTop";
import config from "../config";
import { loadData } from "../shared/utility";
import qs from "qs";
import { useVisibility } from "../hooks";
import { makeAnalyticsFunnel } from "../store/actions/CommonActions";
import { useDispatch } from "react-redux";
import axios from "../axios_purchase";
import { ToastContainer, toast } from "react-toastify";
// import MainComponent from "../components/storefront/MainComponent/index";
import SpringLayout from "../hoc/Layout/SpringLayout/index";
import StorefrontRouts from "../StoreforntRoutes";
import Loader from "../components/mall/sub-pages/Loader";
// import themes from "../themes.json";
import "react-lazy-load-image-component/src/effects/blur.css";
import MatrixEffect from "../../src/components/storefront/oStudio/oStudioComponents/MatrixEffect";
import Skeleton from "react-loading-skeleton";
const NotFoundStore = lazy(() => import("../components/storefront/UI/NotFoundStore"));
const WebSkeletonHome = lazy(() => import("../components/mall/sub-pages/WebSkeletonHome"));
const WebSkeletonStoreFront = lazy(() => import("../components/mall/sub-pages/StoreFrontSkeleton"));
const WebSkeletonProductDetail = lazy(() => import("../components/mall/sub-pages/ProductSkeleton"));
// const MainComponent = lazy(() =>
//     import("../components/storefront/MainComponent/index")
// );
// const StorefrontRouts = lazy(() => import("../StoreforntRoutes"));
const MalltRoutes = lazy(() => import("../MallRoutes"));
const StoreFrontFooter = lazy(() => import("../components/storefront/partials/StoreFrontFooter"));

function App({
    // State
    current_layout,
    // function
    addDefaultValue,
    loadMarketData,
    loadInitialData,
    removeCategories,
    setHeaderContentFunc,
    setCurrentThemeFunc,
    setCurrentMenuFunc,
    setCurrentFaqsFunc,
    setCurrentTestimonialsFunc,
    unSetCurrentTheme,
    updateCartItems,
    updateUserLoggedInData,
    updateFinalCartData,
}) {
    const dispatch = useDispatch();
    const [showLoader, setshowLoader] = useState(false);
    const [state, setState] = useState({
        showHeader: true,
        loader: true,
        initialDataLoaded: false,
        fromPaySafe: "N",
        categories: [
            {
                id: "25",
                category_name: "Electronics",
            },
            {
                id: "1",
                category_name: "Fashion",
            },
            {
                id: "91",
                category_name: "Health_&_Beauty",
            },
            {
                id: "405",
                category_name: "Home_&_Lifestyle",
            },
            {
                id: "305",
                category_name: "Baby_&_Toys",
            },
            {
                id: "9",
                category_name: "Sports_&_Outdoor",
            },
            {
                id: "265",
                category_name: "Retail_&_Grocery",
            },
            {
                id: "231",
                category_name: "Automotive_&_Motorbike",
            },
            {
                id: "407",
                category_name: "Food",
            },
        ],
        css: "",
        layoutType: "",
        name: "",
        storeNotfound: false,
    });

    const location = useLocation();
    const history = useHistory();
    const visibility = useVisibility();

    useEffect(() => {
        if (window.$isStoreFront || window.$isCombinedView) {
            addDefaultValue("loadingStorefrontData", true);
            handleLoadData(
                qs.parse(location.search, {
                    ignoreQueryPrefix: true,
                })
            );
        }
        if (window.$isShortURL) {
            handleShortUrl();
        }
        removeOldCart();
    }, []);

    useEffect(() => {
        const cartItem = localStorage.getItem("cartItems");
        if (visibility && cartItem) {
            updateCartItems(JSON.parse(cartItem));
        }
    }, [visibility]);

    useEffect(() => {
        setState((state) => ({
            ...state,
            showHeader: location.pathname === "/" || location.pathname === config.base ? true : false,
        }));
    }, [location.pathname]);

    function removeOldCart() {
        let sessionTime = new Date().toLocaleString();
        if (!window.localStorage.getItem("cartTime")) {
            updateCartItems([]);
            window.localStorage.setItem("cartTime", sessionTime);
        } else {
            let sTime = window.localStorage.getItem("cartTime");
            let starTime = new Date(sTime);
            let endDate = new Date();
            let s = endDate.getTime() - starTime.getTime();
            let diffInMinutes = Math.round(s / 60000);
            let diffInHours = Math.floor(diffInMinutes / 60);
            if (diffInHours > 24) {
                updateCartItems([]);
                window.localStorage.setItem("cartTime", sessionTime);
            }
        }
    }
    // async function fetchTheme() {
    // 	let url = window.location.hostname;
    // 	if(url.includes("www.")){
    // 		url = url.replace("www.","");
    // 	}
    // 	if(url.includes("localhost") > 0){
    // 		url = "letsyozo.com";
    // 	}
    // 	console.log(qs.parse(location.search, {
    // 				ignoreQueryPrefix: true,
    // 			}))
    // 	const path = themes[url];
    // 	// const path = themes[window.location.hostname];
    // 	if (path !== undefined && path !== "") {
    // 		try {
    // 			const jsonModule = await fetch(`${"https://" + url + "/demo" + path}`);
    // 			if(jsonModule){
    // 				const jsonData = await jsonModule.json();
    // 				if (jsonData?.data?.is_market === "N") {
    // 					window.$storeId = jsonData?.data?.store?.data?.store_id ?? "";
    // 				}
    // 				handleResponse(jsonData?.data);
    // 			} else{
    // 				if (window.$isStoreFront || window.$isCombinedView) {
    // 					addDefaultValue("loadingStorefrontData", true);
    // 					handleLoadData(
    // 						qs.parse(location.search, {
    // 							ignoreQueryPrefix: true,
    // 						}),
    // 						true
    // 					);
    // 				}
    // 			}
    // 		} catch (error) {
    // 			console.error('Error importing JSON module:', error);
    // 			if (window.$isStoreFront || window.$isCombinedView) {
    // 				addDefaultValue("loadingStorefrontData", true);
    // 				handleLoadData(
    // 					qs.parse(location.search, {
    // 						ignoreQueryPrefix: true,
    // 					}),
    // 					true
    // 				);
    // 			}
    // 			// Handle error here
    // 		}
    // 	}
    // 	return false;
    // }
    function handleLoadData(qParams, flag = false) {
        // if (!flag) {
        // 	fetchTheme();
        // 	return;
        // }
        loadData(qParams)
            .then((response) => {
                window.localStorage.setItem(window.location.hostname, JSON.stringify(response));
                if (response) {
                    handleResponse(response);
                    dispatch(makeAnalyticsFunnel("storevisit", "Home page", 0));
                    window.localStorage.setItem("store_visit", true);
                } else {
                    addDefaultValue("loadingStorefrontData", false);
                    setState((state) => ({
                        ...state,
                        storeNotfound: true,
                    }));
                }
            })
            .catch((err) => {
                addDefaultValue("loadingStorefrontData", false);
            });
    }

    const useInsertionEffect = (callback, dependencies) => {
        const isFirstRender = useRef(true);

        useEffect(() => {
            if (!isFirstRender.current) {
                callback();
            } else {
                isFirstRender.current = false;
            }
        }, dependencies);
    };

    function handleResponse(response) {
        if (response?.themeData?.data) {
            var themeresponse = [];
            var themeresponse1 = response?.themeData?.data
                ? JSON.parse(response?.themeData?.data.replace(/\\/g, ""))
                : null;
            if (Array.isArray(themeresponse1?.data)) {
                themeresponse = themeresponse1;
            } else {
                themeresponse = JSON.parse(JSON.parse(response?.themeData?.data.replace(/\\/g, "")));
            }
            if (themeresponse) {
                setState((state) => ({
                    ...state,
                    css: themeresponse?.layout?.css,
                    layoutType: themeresponse?.layout?.name,
                    name: themeresponse?.layout?.name,
                }));
                var content = themeresponse.layout.components.filter((Item) => Item?.name === "ThemeHeader");
                if (content && content.length) {
                    setHeaderContentFunc(content[0].content);
                }
                setCurrentThemeFunc(themeresponse);
            }
            let menurepsonse = null;
            let menuJSON = response?.menuData?.content.replace(/\\/g, "");
            // menuJSON = menuJSON.replace(/""/g, '"');
            let menurepsonse1 = response?.menuData?.content ? JSON.parse(menuJSON) : null;
            if (!Array.isArray(menurepsonse1)) {
                menurepsonse = response?.menuData?.content
                    ? JSON.parse(JSON.parse(response?.menuData?.content.replace(/\\/g, "")))
                    : null;
            } else {
                menurepsonse = menurepsonse1;
            }

            if (menurepsonse) setCurrentMenuFunc(menurepsonse);

            // FAQs
            if (response?.faqsData) {
                let faqsrepsonse = null;
                let faqsrepsonse1 = response?.faqsData?.content
                    ? JSON.parse(response?.faqsData?.content.replace(/\\/g, ""))
                    : null;
                if (!Array.isArray(faqsrepsonse1)) {
                    faqsrepsonse = response?.faqsData?.content
                        ? JSON.parse(JSON.parse(response?.faqsData?.content.replace(/\\/g, "")))
                        : null;
                } else {
                    faqsrepsonse = faqsrepsonse1;
                }
                if (faqsrepsonse) setCurrentFaqsFunc(faqsrepsonse);
            }

            // Testimonials
            if (response?.testmonialsData) {
                let testimonialsrepsonse = null;
                let testimonialsrepsonse1 = response?.testmonialsData?.content
                    ? JSON.parse(response?.testmonialsData?.content)
                    : null;
                if (!Array.isArray(testimonialsrepsonse1)) {
                    testimonialsrepsonse = response?.testmonialsData?.content
                        ? JSON.parse(JSON.parse(response?.testmonialsData?.content))
                        : null;
                } else {
                    testimonialsrepsonse = testimonialsrepsonse1;
                }
                if (testimonialsrepsonse) {
                    setCurrentTestimonialsFunc(testimonialsrepsonse);
                }
            }
        } else {
            unSetCurrentTheme();
        }
        if (
            (response?.branches?.data.count > 1 && parseInt(response?.config[0]?.branch_selection_type) === 1) ||
            (parseInt(response?.config[0]?.branch_selection_type) === 2 && response?.branchSelectionRequired)
        ) {
            window.$allowBranchModal = true;
            if (response?.branchSelectionRequired) {
                window.$BranchModalTitle = "Country";
            }
            if (
                window.localStorage.getItem("branchHost") &&
                window.localStorage.getItem("branchHost") !== "" &&
                localStorage.getItem("actualHost") === window.location.host &&
                window.localStorage?.getItem("branchData") &&
                window.localStorage?.getItem("branchData") !== "" &&
                window.localStorage?.getItem("branchData") !== "undefined"
            ) {
                window.$showBranchModal = false;
            } else {
                window.localStorage.removeItem("branchHost");
                window.localStorage.removeItem("branchData");
                window.$showBranchModal = true;
            }
        } else {
            window.localStorage.removeItem("branchHost");
            window.localStorage.removeItem("branchData");
            window.$allowBranchModal = false;
            window.$showBranchModal = false;
        }
        if (window.$isMarket) loadMarketData(response);
        else {
            loadInitialData(response);
            removeCategories();
        }
        addDefaultValue("loadingStorefrontData", false);
        setState((state) => ({
            ...state,
            initialDataLoaded: true,
        }));
        if (
            window.$isFromEasypaisaApp &&
            window.mp &&
            window.location.href.indexOf("payments" <= 0) &&
            window.$easyPaisaOpenId === ""
        ) {
            const scopes = ["USER_MSISDN", "USER_NAME", "OPEN_ID", "UNION_ID", "USER_CNIC"];
            let easypaisaResult = window.mp?.getUserAuthCode(scopes);
            window.getUserAuthCodeResult(easypaisaResult);
        } else if (window.$easyPaisaOpenId !== "") {
            setshowLoader(true);
            let postData = {
                section: "getEasypaisaUserInfo",
                authCode: "",
                store_id: window.$storeId,
                openId: window.$easyPaisaOpenId,
            };
            const res = axios
                .post(window.$storeUrl + window.$apiRoot + "/customer/getEasypaisaUserInfo", postData)
                .then((res) => {
                    if (res.data.success === "Y") {
                        let successData = res.data.response.data;

                        let userData = []; //this.props.userLoggedInData;
                        userData.is_loggedIn = true;
                        userData.mobile_no = successData.customer_mobile;
                        userData.token = successData.token;
                        userData.user_id = successData.customer_id;
                        userData.user_name = successData.customer_name;
                        userData.email = successData.customer_email;
                        if (window.$storeId === "1791" || window.$storeId === "841705") {
                            userData.is_applied = successData?.is_applied;
                            userData.is_inquired = successData?.is_inquire;
                        }

                        window.localStorage.setItem("user_data", JSON.stringify(userData));
                        updateUserLoggedInData(userData);
                        if (window.$isWebView) {
                            window.ReactNativeWebView.postMessage(JSON.stringify(userData));
                        }

                        this.setState({
                            showLoginError: true,
                            otp: "",
                            loginError: "Not Authorized",
                            otpSent: false,
                        });
                        setshowLoader(false);
                    } else {
                        setshowLoader(false);
                        const scopes = ["USER_MSISDN", "USER_NAME", "OPEN_ID", "UNION_ID", "USER_CNIC"];
                        let easypaisaResult = window.mp?.getUserAuthCode(scopes);
                        window.getUserAuthCodeResult(easypaisaResult);
                    }
                })
                .catch((err) => {
                    setshowLoader(false);
                });
        }
    }
    window.createPaymentResult = (jsonResult) => {
        var result = JSON.parse(jsonResult);
        dispatch(makeAnalyticsFunnel("CreatePaymentResult", JSON.stringify(jsonResult), window.$storeId));
        setshowLoader(true);
        if (result.paymentStatus == "S" && result.resultCode == "9000") {
            let postData = {
                order_id: result.paymentTransactionID,
                store_id: window.$storeId,
                openId: window.$easyPaisaOpenId,
            };
            const res = axios
                .post(window.$storeUrl + window.$apiRoot + "/cart/verifyEasypaisaPayment", postData)
                .then((res) => {
                    dispatch(makeAnalyticsFunnel("EasyPaisaVerifyPaymentResponse", res, window.$storeId));
                    if (res.data.success === "Y") {
                        let url = "/paymentsuccess/" + window.$successURL;
                        history.push(url);
                        localStorage.removeItem("cart_data");
                        localStorage.removeItem("final_cart");

                        setTimeout(() => {
                            updateFinalCartData([]);
                            updateCartItems([]);
                        }, 10000);
                        // window.location.href = url
                    } else {
                        alert("Verifying Payment.");
                        let url = "/";
                        history.push(url);
                        localStorage.removeItem("cart_data");
                        localStorage.removeItem("final_cart");

                        setTimeout(() => {
                            updateFinalCartData([]);
                            updateCartItems([]);
                        }, 10000);
                        dispatch(
                            makeAnalyticsFunnel(
                                "EasyPaisaSuccessOrFailure",
                                "Payment made but issue in webhook",
                                window.$storeId
                            )
                        );
                    }
                    setshowLoader(false);
                })
                .catch((err) => {});
        } else {
            setshowLoader(false);
            toast.error(result.errorDescription + " (" + result.resultCode + ")");
        }
    };
    window.getLocationResult = (jsonResult) => {
        var result = JSON.parse(jsonResult);
        window.$easyPaisalat = result.latitude;
        window.$easyPaisalong = result.longitude;
        // alert('status:' + result.status);
        // alert('location:' + result.longitude + ',' + result.latitude);
        // alert('accuracy:' + result.accuracy);
        // alert('error:' + result.errorCode);
        return result;
    };
    window.getUserAuthCodeResult = (jsonResult) => {
        var result = JSON.parse(jsonResult);
        setshowLoader(true);
        window.$easyPaisaAuthCode = result?.authCode;
        let postData = {
            section: "getEasypaisaUserInfo",
            authCode: result?.authCode,
            store_id: window.$storeId,
            openId: window.$easyPaisaOpenId,
        };
        const res = axios
            .post(window.$storeUrl + window.$apiRoot + "/customer/getEasypaisaUserInfo", postData)
            .then((res) => {
                if (res.data.success === "Y") {
                    let successData = res.data.response.data;

                    let userData = []; //this.props.userLoggedInData;
                    userData.is_loggedIn = true;
                    userData.mobile_no = successData.customer_mobile;
                    userData.token = successData.token;
                    userData.user_id = successData.customer_id;
                    userData.user_name = successData.customer_name;
                    userData.email = successData.customer_email;
                    if (window.$storeId === "1791" || window.$storeId === "841705") {
                        userData.is_applied = successData?.is_applied;
                        userData.is_inquired = successData?.is_inquire;
                    }

                    window.localStorage.setItem("user_data", JSON.stringify(userData));
                    updateUserLoggedInData(userData);
                    if (window.$isWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify(userData));
                    }

                    this.setState({
                        showLoginError: true,
                        otp: "",
                        loginError: "Not Authorized",
                        otpSent: false,
                    });
                    setshowLoader(false);
                } else {
                    setshowLoader(false);
                }
            })
            .catch((err) => {
                setshowLoader(false);
            });
    };

    function handleShortUrl() {
        let path = location.pathname;
        let pathStrings = path.split("/");
        let finalString = pathStrings[1];
        let environment = "";

        if (pathStrings.length > 3) {
            if (!["O", "R", "M", "p"].includes(finalString.charAt(0))) {
                finalString = pathStrings[2];
                environment = "/" + pathStrings[1];
            }
        }

        if (["/qa", "/demo", "/jawad", "/beta", ""].includes(environment)) {
            const urlToRedirect = createRedirectUrl({
                firstCharacter: finalString.charAt(0),
                secondCharacter: finalString.charAt(1),
                orderId: finalString.substring(1),
                environment,
                finalString,
                pathStrings,
            });
            urlToRedirect && history.push(urlToRedirect);
        }
    }

    function createRedirectUrl({ firstCharacter, secondCharacter, orderId, environment, finalString, pathStrings }) {
        switch (firstCharacter) {
            case "O":
                return `${environment}/order-confirm/${orderId}`;
            case "A":
                const storeId = window.$storeId ? window.$storeId : 816691;
                return `${environment}/appointment-status/${btoa(orderId)}/${btoa(storeId)}`;
            case "M":
                return `${environment}/order-confirm/${orderId}`;
            case "X":
                window.$orderConfirmedId = orderId;
                window.$fromPaysafe = "Y";
                return environment + "/";
            case "R":
                const lastPathString = pathStrings[pathStrings.length - 1];
                return `${environment}/order-rating/${orderId}/${lastPathString}`;
            case "p":
                if (/^\d+$/.test(finalString)) {
                    const num = parseInt(finalString, 10);
                    if (!isNaN(num)) {
                        const finalStr = btoa(num);
                        return `${environment}/product-detail/${finalStr}`;
                    }
                }
                return null;
            default:
                return null;
        }
    }

    // const goBack = () => document.getElementById("btnBackTo").click();

    const falBackLoader = (
        <div className="skeletonn">
            <div className="s-img"></div>
        </div>
    );

    let Skelton = <WebSkeletonStoreFront />;
    if (!window.$isStoreFront) Skelton = <WebSkeletonHome />;
    if (window.location.href.indexOf("products") > 0) {
        Skelton = <WebSkeletonProductDetail />;
    }
    // Skelton = <MatrixEffect/>;
    if (state?.name === "ThemeLayout") Skelton = falBackLoader;
    useInsertionEffect(() => {
        // Create a <link> element
        if (window.$isStoreFront && state.css !== "") {
            const linkElement = document.createElement("link");
            linkElement.rel = "stylesheet";
            linkElement.type = "text/css";
            linkElement.href = `${process.env.PUBLIC_URL}/${state?.css}`;

            // Append the <link> element to the <head> of the document
            document.head.appendChild(linkElement);

            // Clean up function to remove the <link> element when component is unmounted
            return () => {
                document.head.removeChild(linkElement);
            };
        }
        // Add your logic for when the component is inserted into the DOM
    }, [state.css]);
    return (
        <>
            {showLoader ? <Loader /> : ""}
            {!window.$isStoreFront || state.initialDataLoaded ? (
                <>
                    {window.location.href.includes("studioo") ? (
                        <>
                            <MatrixEffect />
                        </>
                    ) : (
                        ""
                    )}
                    <AppDukanMall
                        {...{
                            showHeader: state?.showHeader,
                            layoutType: state?.layoutType,
                            layoutName: state?.name,
                            Skelton,
                        }}
                    />
                </>
            ) : state.storeNotfound ? (
                <NotFoundStore />
            ) : (
                Skelton
            )}
        </>
    );
}

export default App;
function AppDukanMall({ Skelton, showHeader, layoutName, layoutType }) {
    return (
        <div className="App dukanmall">
            <ToastContainer autoClose={3000} />
            <ScrollToTop>
                {(() => {
                    if (!window.$isStoreFront) {
                        return (
                            <Suspense fallback={Skelton}>
                                <MalltRoutes showHeader={showHeader} />
                            </Suspense>
                        );
                    } else if (showHeader && layoutName === "ThemeLayout") {
                        if (layoutType !== "MultiCourouselSlider") {
                            return (
                                <>
                                    <SpringLayout>
                                        <StorefrontRouts />
                                    </SpringLayout>
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <StorefrontRouts />
                                </>
                            );
                        }
                    }
                    return <StorefrontRouts />;
                })()}

                {!!(window.$isStoreFront && layoutName !== "ThemeLayout") && (
                    <StoreFrontFooter showHeader={showHeader} />
                )}
            </ScrollToTop>
        </div>
    );
}
